<template>
  <div class="dashboard-container">
    <!-- Subscription and Other Modals (Optional) -->
    <subscription-modal
      :visible="!isSubscribed && showModal"
      :products="products"
      @requestCloseModal="closeModal"
    ></subscription-modal>

    <DeleteConfirmationModal
      v-if="showDeleteModal"
      :dossierName="dossierToDelete?.name"
      @confirm="confirmDeleteDossier"
      @cancel="cancelDeleteDossier"
    />

    <!-- Header Section -->
    <div class="dashboard-header">
      <div class="logo">
        <img src="@/assets/w-q-white.png" alt="logo" class="logo-image">
      </div>
      <button @click="signOut" class="disconnect-button">Se déconnecter</button>
    </div>

    <!-- Main Content Container -->
    <div class="main-content-container">
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Moteur de recherche Button -->
        <!-- <button @click="goToHomePage" class="sidebar-button">
          <div class="button-copilote" style="display: flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-mouse" viewBox="0 0 16 16" style="margin-top: 5px; margin-right: 5px;">
              <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3m4 8a4 4 0 0 1-8 0V5a4 4 0 1 1 8 0zM8 0a5 5 0 0 0-5 5v6a5 5 0 0 0 10 0V5a5 5 0 0 0-5-5"/>
            </svg>
            Moteur de recherche
          </div>
        </button> -->
        <button @click="goToContactPage" class="sidebar-button">
          <div class="button-contact" style="display: flex">
            <!-- Envelope Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16" style="margin-top: 6px; margin-right: 5px;">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v0.5l-8 5-8-5V4z"/>
              <path d="M0 5.5l8 5 8-5V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.5z"/>
            </svg>
            Nous contacter
          </div>
        </button>
        <!-- Add more sidebar items here if needed -->
      </div>

      <!-- Main Content -->
      <div class="dashboard-content">
        <!-- Nouvelle recherche avec Copilote Button -->
        <button @click="createAndOpenDossier" class="dossier-card" id="copilot-but">
          <div class="button-copilote" style="display: flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="black" class="bi bi-plus-circle" viewBox="0 0 16 16" style="margin-right: 6px; margin-top: 3px;">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
            </svg>
            Recherchez avec Copilote
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-android" viewBox="0 0 16 16" style="margin-top: 4px; margin-left: 5px;">
              <path d="M2.76 3.061a.5.5 0 0 1 .679.2l1.283 2.352A8.9 8.9 0 0 1 8 5a8.9 8.9 0 0 1 3.278.613l1.283-2.352a.5.5 0 1 1 .878.478l-1.252 2.295C14.475 7.266 16 9.477 16 12H0c0-2.523 1.525-4.734 3.813-5.966L2.56 3.74a.5.5 0 0 1 .2-.678ZM5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2m6 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
            </svg>
          </div>
        </button>

        <!-- SPINNER DE CHARGEMENT -->
        <div v-if="isLoadingDossiers" class="spinner-container">
          <div class="spinner"></div>
        </div>

        <!-- Dossiers Section (lorsque ce n'est plus en chargement) -->
        <div v-else-if="dossiers.length > 0" class="dossiers-section">
          <h2>Vos Dossiers</h2>
          <div class="dossiers-grid">
            <div
              v-for="dossier in displayedDossiers"
              :key="dossier.id"
              class="dossier-card"
              @click="openDossier(dossier)"
            >
              <div class="dossier-info">
                <span class="dossier-name">{{ dossier.name }}</span>
              </div>
              <div class="dossier-actions">
                <button
                  @click.stop="deleteDossier(dossier)"
                  class="delete-dossier-button"
                  title="Supprimer le dossier"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="black" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <!-- Plus de Dossiers Button -->
          <button
            v-if="dossiers.length > 4"
            @click="toggleShowAllDossiers"
            class="toggle-dossiers-button"
          >
            <div class="button-icon" style="display: flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="black" class="bi bi-plus-circle" viewBox="0 0 16 16" style="margin-right: 6px; margin-top: 6px;">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
              </svg>
              {{ showAllDossiers ? 'Moins de dossiers' : 'Plus de dossiers' }}
            </div>
          </button>
        </div>

        <!-- État vide s'il n'y a pas de dossiers et qu'on ne charge plus -->
        <div v-else class="empty-state">
          <div class="empty-state-container">
            <h2>Bienvenue dans votre tableau de bord</h2>
            <p>
              Il semble que vous n’ayez pas encore de dossiers créés. Utilisez notre <strong>Copilote</strong> pour commencer votre première recherche juridique en quelques clics!
            </p>
            <p>
              Découvrez également notre <strong>Bibliothèque d'exemples pratiques</strong> pour vous aider à mieux comprendre et gérer vos dossiers.
            </p>
            <p>
              <strong>Comment utiliser le Copilote ?</strong>
            </p>
            <ul>
              <li>Cliquez sur le bouton <em>"Recherchez avec Copilote"</em> ci-dessus.</li>
              <li>Décrivez brièvement le cas ou la question juridique que vous souhaitez explorer, en donnant <strong>un maximum de contexte</strong>.</li>
              <li>Laissez le Copilote analyser et générer des recommandations basées sur la jurisprudence et les textes juridiques pertinents.</li>
            </ul>
            <button @click="goToExamplePage" class="start-copilot-button">
              Explorez la Bibliothèque d'Exemples
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import SubscriptionModal from './SubscriptionModal.vue';
import DeleteConfirmationModal from './DeleteConfirmationModal.vue';
import { auth } from "@/firebase/firebaseConfig";
import { logout } from '@/firebase/auth';
import { db } from "@/firebase/firebaseConfig";
import { collection, query, where, doc, getDocs, deleteDoc, addDoc, serverTimestamp, orderBy } from 'firebase/firestore';
// import { getFunctions, httpsCallable } from "firebase/functions";

// const functions = getFunctions();
// const createPortalLink = httpsCallable(functions, 'createStripeCustomerPortalLink');

export default {
  name: 'DashboardPage',

  components: {
    SubscriptionModal,
    DeleteConfirmationModal,
  },

  data() {
    return {
      showDeleteModal: false,
      dossierToDelete: null,
      dossiers: [],
      products: [],
      isSubscribed: false, // Initialize based on your logic
      showModal: false, // Boolean value
      isLoading: false,
      isLoadingDossiers: false,
      trialing: false,
      showAllDossiers: false, // Controls whether to show all dossiers
    };
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.fetchSubscriptionStatus();
    this.fetchUserDossiers();
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    // Determines which dossiers to display based on showAllDossiers
    displayedDossiers() {
      if (this.showAllDossiers) {
        return this.dossiers;
      }
      return this.dossiers.slice(0, 4);
    },
  },

  methods: {
    // Handle clicks outside modals if necessary
    // handleClickOutside(event) {
    //   // Implement if needed
    // },

    // Fetch user dossiers from Firebase
    async fetchUserDossiers() {
      this.isLoadingDossiers = true;
      try {
        const userId = auth.currentUser.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');

        // Fetch dossiers sorted by 'createdAt' in descending order
        const q = query(dossiersRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);

        // Map the fetched documents to the dossiers array
        this.dossiers = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching dossiers:', error);
      } finally {
        // Arrêt de l'animation de chargement
        this.isLoadingDossiers = false;
      }
    },


    // Create and open a new dossier
    async createAndOpenDossier() {
      // Check subscription status
      try {
        const userId = auth.currentUser.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');

        // Create new dossier with a 'createdAt' field
        const newDossier = {
          name: 'Nouveau dossier',
          createdAt: serverTimestamp(),  // Adds the timestamp of creation
          // Include any other default fields if necessary
        };

        // Add the dossier to Firestore
        const docRef = await addDoc(dossiersRef, newDossier);

        // Optionally update the local dossiers array
        this.dossiers.unshift({ id: docRef.id, ...newDossier });

        // Navigate to the dossier page
        this.$router.push({ name: 'DossierPage', params: { dossierId: docRef.id } });
      } catch (error) {
        console.error('Error creating dossier:', error);
        alert('Erreur lors de la création du dossier.');
      }
    },

    async checkUserSubscriptionStatus() {
      console.log('Checking subscription status...');
      const userId = auth.currentUser.uid;
      const subscriptionsRef = collection(db, `customers/${userId}/subscriptions`);
      const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          // Show the modal if no active/trialing subscription
          this.showModal = true;
        } else {
          this.showModal = false;
        }
      } catch (error) {
        this.isSubscribed = false;
        this.trialing = false;
        this.showModal = true; // Show modal on error
        console.error('Error fetching subscription:', error);
      }
    },

    // Open a specific dossier
    openDossier(dossier) {
      this.$router.push({ name: 'DossierPage', params: { dossierId: dossier.id } });
    },

    // Delete a dossier
    deleteDossier(dossier) {
      this.dossierToDelete = dossier;
      this.showDeleteModal = true;
    },

    // Confirm deletion of a dossier
    async confirmDeleteDossier() {
      if (!this.dossierToDelete) return;
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.dossierToDelete.id;

        // Delete all cases within the dossier
        const casesRef = collection(db, 'users', userId, 'dossiers', dossierId, 'cases');
        const casesSnapshot = await getDocs(casesRef);
        const deleteCasePromises = casesSnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deleteCasePromises);

        // Delete the dossier document
        const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
        await deleteDoc(dossierDocRef);

        // Remove the dossier from the local array
        this.dossiers = this.dossiers.filter(d => d.id !== dossierId);

        // Reset the modal and dossierToDelete
        this.dossierToDelete = null;
        this.showDeleteModal = false;

      } catch (error) {
        console.error('Error deleting dossier:', error);
        alert('Erreur lors de la suppression du dossier.');
        // Reset the modal and dossierToDelete even if there's an error
        this.dossierToDelete = null;
        this.showDeleteModal = false;
      }
    },

    // Cancel deletion
    cancelDeleteDossier() {
      this.dossierToDelete = null;
      this.showDeleteModal = false;
    },

    // Toggle the display of all dossiers
    toggleShowAllDossiers() {
      this.showAllDossiers = !this.showAllDossiers;
    },

    // Navigate to Home Page (Moteur de recherche)
    goToHomePage() {
      this.$router.push({ name: 'home' }); // Ensure 'HomePage' is correctly named in your router
    },

    goToContactPage() {
      this.$router.push({ name: 'contact' });
    },

    // Sign out the user
    async signOut() {
      try {
        await logout();
        this.$router.replace({ path: '/' });
      } catch (error) {
        alert(error.message); // Consider using a more user-friendly error handling approach
      }
    },

    // Fetch subscription status
    async fetchSubscriptionStatus() {
      if (!auth.currentUser) {
        this.subscriptionStatus = "User not authenticated";
        this.loading = false;
        return;
      }

      const userId = auth.currentUser.uid;
      const subscriptionsRef = collection(db, `customers/${userId}/subscriptions`);
      const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          console.log("Subscription found:", querySnapshot.docs[0].data());
          // Assuming the first active/trialing subscription is the current one
          const subscription = querySnapshot.docs[0].data();
          this.isSubscribed = subscription.status === "active";
          this.trialing = subscription.status === "trialing";
        } else {
          console.log("No active/trialing subscription found");
          this.isSubscribed = false;
          this.trialing = false;
        }
      } catch (error) {
        this.isSubscribed = false;
        this.trialing = false;
        this.showModal = true; // Show modal on error
        console.error('Error fetching subscription:', error);
      }
    },

    // Close the subscription modal
    closeModal() {
      this.showModal = false;
    },

    // Navigate to the Example Page
    goToExamplePage() {
      this.$router.push({ name: 'example' });
    },
  },
};
</script>

<style scoped>

/* Conteneur du spinner pour le centrer */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

/* Style du spinner (cercle tournant) */
.spinner {
  border: 8px solid #f3f3f3;       /* Couleur de fond (clair) */
  border-top: 8px solid #343433;   /* Couleur du "cercle" */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite; /* Durée et type d'animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 2em;
  padding: 1em;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.empty-state h2 {
  font-size: 1.5em;
  margin-bottom: 0.8em;
}

.empty-state p {
  font-size: 1em;
  margin: 0.5em 0;
}

.empty-state ul {
  text-align: left;
  margin: 1em 0;
  padding-left: 1.5em;
}

.empty-state ul li {
  margin-bottom: 0.5em;
  list-style-type: disc;
}

.start-copilot-button {
  margin-top: 1em;
  padding: 10px 20px;
  background-color: #343433;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.start-copilot-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* General Styles */
.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  color: black;
}

/* Header Section */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #343433;
  padding: 1em;
  color: white;
}

.logo-image {
  width: 75px;
  height: auto;
  border-radius: 8px;
}

.disconnect-button {
  border: none;
  padding: 10px 20px;
  background-color: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.disconnect-button:hover {
  background-color: lightgray;
  color: black;
}

/* Layout Styles */
.main-content-container {
  display: flex;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

/* Sidebar Styles */
.sidebar {
  width: 250px;
  background-color: white;
  padding: 1em;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.sidebar-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  background-color: #f0f0f0;
  color: black;
  font-size: 1em;
  padding: 10px 20px;
  margin-bottom: 1em;
  margin-top: 1em;
  text-align: left;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.2s ease, transform 0.2s ease;
  cursor: pointer;
}

.sidebar-button:hover {
  background-color: #f0f0f0;
  transform: translateX(5px);
}

/* Main Content Styles */
.dashboard-content {
  flex: 1;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#copilot-but {
  align-items: center;
  padding: 1em;
  margin-bottom: 1em;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: transform 0.2s, box-shadow 0.2s;
}

#copilot-but:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dossiers-section {
  width: 100%;
  max-width: 1200px;
  margin-top: 2em;
  text-align: center;
}

.dossiers-section h2 {
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 300;
  border-bottom: 0.8px solid black;
  padding-bottom: 0.5em;
}

.dossiers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1em;
  margin-top: 1em;
}

.dossier-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.dossier-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dossier-info {
  display: flex;
  align-items: center;
}

.dossier-name {
  font-size: 1em;
  color: black;
}

.dossier-actions {
  display: flex;
  align-items: center;
}

.delete-dossier-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.delete-dossier-button:hover {
  background-color: #f8d7da;
  color: #c0392b;
}

/* Toggle Button */
.toggle-dossiers-button {
  margin-top: 1.5em;
  padding: 10px 20px;
  background-color: white;
  border: 1px solid black;
  color: black;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: transform 0.2s, box-shadow 0.2s;
}

.toggle-dossiers-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 900px) {
  .main-content-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    padding: 1em;
    box-shadow: none;
  }

  .dashboard-content {
    width: 100%;
  }

  .dossiers-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  .sidebar-button {
    font-size: 0.9em;
    padding: 8px 12px;
  }

  .dossier-card {
    padding: 0.8em;
  }

  .dossier-name {
    font-size: 0.9em;
  }

  .toggle-dossiers-button {
    font-size: 0.9em;
    padding: 8px 12px;
  }
}

</style>
