<template>
  <div class="container">
    <h1>Décision à Anonymiser</h1>
    <div class="columns">
      <!-- LEFT SIDE: Original Decision Input -->
      <div class="column">
        <label for="original-decision">Texte original</label>
        <textarea
          id="original-decision"
          v-model="decisionText"
          placeholder="Collez ici votre décision à anonymiser..."
          rows="12"
        ></textarea>
        <button
          :disabled="status === 'pending'"
          @click="submitDecision"
        >
          {{ status === 'pending' ? 'En cours...' : 'Anonymiser' }}
        </button>
      </div>

      <!-- RIGHT SIDE: Anonymized Decision Output -->
      <div class="column">
        <label>Texte anonymisé</label>
        <div v-if="status === 'pending'" class="loader-section">
          <p>Patientez pendant l'anonymisation...</p>
          <!-- Simple loader animation or spinner -->
        </div>
        <div v-else-if="status === 'finished'">
          <pre class="anonymized-text">{{ anonymizedText }}</pre>
        </div>
        <div v-else>
          <p>Le texte anonymisé sera affiché ici.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import {
  doc,
  onSnapshot
} from "firebase/firestore";

export default {
  name: "AnonymizeDecision",
  data() {
    return {
      decisionText: "",
      decisionId: null,
      status: null,          // "pending" | "finished" | null
      anonymizedText: "",    // Filled once 'status' = "finished"
      unsubscribe: null      // to stop the listener if needed
    };
  },
  methods: {
    async submitDecision() {
      if (!this.decisionText) {
        this.$toast.error("Veuillez fournir un texte de décision.");
        return;
      }

      // Reset states
      this.status = "pending";
      this.anonymizedText = "";

      try {
        const userId = auth.currentUser.uid; // or however you track your user

        // 1) Send POST request to your Flask API
        const response = await fetch(
          "https://api.query-juriste.com/copilot/anonymize_decision",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              decision_text: this.decisionText,
              userId: userId
            })
          }
        );

        if (!response.ok) {
          throw new Error(`Server error: ${response.statusText}`);
        }

        // 2) Get back the { task_id, decision_id }
        const data = await response.json();
        this.decisionId = data.decision_id;

        // 3) Start listening to Firestore doc for the anonymization status
        this.listenToAnonymizationStatus(userId, this.decisionId);

      } catch (error) {
        console.error("Error submitting decision:", error);
        this.$toast.error("Échec de l'envoi de la décision à anonymiser.");
        this.status = null;
      }
    },

    listenToAnonymizationStatus(userId, decisionId) {
      // 1) Firestore reference for the anonymized doc
      const decisionDocRef = doc(
        db,
        "users",
        userId,
        "anonyme_decisions",
        decisionId
      );

      // 2) onSnapshot real-time listener
      this.unsubscribe = onSnapshot(
        decisionDocRef,
        (docSnapshot) => {
          if (!docSnapshot.exists()) {
            console.warn("Document inexistant:", decisionId);
            return;
          }

          const data = docSnapshot.data();
          const previousStatus = this.status;
          this.status = data.status || null;

          // If anonymization just finished
          if (previousStatus === "pending" && this.status === "finished") {
            this.$toast.success("L'anonymisation est terminée !");
            this.anonymizedText = data.anonymized_decision || "";
          }
        },
        (error) => {
          console.error("Erreur lors de la récupération du status:", error);
          this.$toast.error("Erreur de liaison avec Firestore.");
        }
      );
    }
  },
  beforeUnmount() {
    // If we have an active listener, unsubscribe before unmount
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.columns {
  display: flex;
  gap: 2rem;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  resize: vertical;
}

button {
  width: 150px;
  padding: 0.5rem;
  cursor: pointer;
}

.loader-section {
  color: #666;
  font-style: italic;
}

.anonymized-text {
  white-space: pre-wrap;
  border: 1px solid #ddd;
  padding: 1rem;
  background: #f7f7f7;
}
</style>
