<template>
  <div class="anonymisation-page">
    <router-link to="/anonymisationDashboard" class="retour-button">
      Retour
    </router-link>
    <h1>Anonymisation - {{ anonymisationId }}</h1>

    <div class="content-section">
      <!-- Side-by-side container -->
      <div class="side-by-side-sections">

        <!-- Original Text Section -->
        <div class="text-section original-text-section">
          <label for="original-decision">Texte original</label>
          <textarea
            id="original-decision"
            v-model="decisionText"
            placeholder="Collez ici votre décision à anonymiser..."
            rows="12"
            :disabled="isSubmitting || status === 'pending'"
          ></textarea>
          <button
            :disabled="isSubmitting || !decisionText || status === 'pending' || status === 'finished'"
            @click="submitDecision"
            class="anonymize-button"
          >
            {{ isSubmitting ? "En cours..." : "Anonymiser" }}
          </button>
        </div>

        <!-- Anonymized Text Section (shown only when finished) -->
        <div
          v-if="status === 'finished'"
          class="text-section anonymized-text-section"
        >
          <h2>Texte Anonymisé</h2>
          <pre class="anonymized-text">{{ anonymizedText }}</pre>
        </div>
      </div>

      <!-- Loader Section (shown only when pending) -->
      <div v-if="status === 'pending'" class="loader-section">
        <div class="loader"></div>
        <p>Patientez pendant l'anonymisation...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

export default {
  name: "AnonymisationPage",
  data() {
    return {
      anonymisationId: null,
      decisionText: "",
      anonymizedText: "",
      status: null,
      isSubmitting: false,
      unsubscribe: null
    };
  },
  methods: {
    async fetchExistingDecision() {
      try {
        console.log("fetchExistingDecision");
        console.log(this.anonymisationId);
        const userId = auth.currentUser.uid;
        const decisionDocRef = doc(
          db,
          "users",
          userId,
          "anonyme_decisions",
          this.anonymisationId
        );

        const docSnapshot = await getDoc(decisionDocRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          this.decisionText = data.original_decision || "";
          this.status = data.status || null;
          this.anonymizedText = data.anonymized_decision || "";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la décision:", error);
      }
    },
    async submitDecision() {
      this.isSubmitting = true;
      this.status = "pending";

      try {
        const userId = auth.currentUser.uid;
        const response = await fetch(
          "https://api.query-juriste.com/copilot/anonymize_decision",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              decision_text: this.decisionText,
              userId: userId,
              anonymisationId: this.anonymisationId
            })
          }
        );

        if (!response.ok) {
          throw new Error("Erreur du serveur");
        }
        this.listenToAnonymizationStatus(userId, this.anonymisationId);
      } catch (error) {
        console.error("Erreur lors de l'envoi de la décision:", error);
      } finally {
        this.isSubmitting = false;
      }
    },
    listenToAnonymizationStatus(userId, anonymisationId) {
      const decisionDocRef = doc(
        db,
        "users",
        userId,
        "anonyme_decisions",
        anonymisationId
      );

      this.unsubscribe = onSnapshot(decisionDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          this.status = data.status || null;
          this.anonymizedText = data.anonymized_decision || "";
        }
      });
    }
  },
  mounted() {
    this.anonymisationId = this.$route.params.anonymisationId;
    this.fetchExistingDecision();
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
};
</script>

<style scoped>
.anonymisation-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.anonymisation-page h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.content-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* NEW: Side-by-side container for both sections */
.side-by-side-sections {
  display: flex;
  gap: 2rem;
}

/*
  NEW: Common class to unify styling for both
  the original-text-section and anonymized-text-section
*/
.text-section {
  flex: 1; /* so both sections share available width */
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
}

.text-section label,
.text-section h2 {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
}

textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
  transition: border-color 0.3s;
}

textarea:focus {
  border-color: #007BFF;
  outline: none;
}

.anonymize-button,
.retour-button {
  align-self: flex-start;
  padding: 0.75rem 1.5rem;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}

.anonymize-button:hover:not(:disabled),
.retour-button:hover {
  background-color: #0056b3;
}

.anonymize-button:disabled {
  background-color: #a0cfff;
  cursor: not-allowed;
}

.retour-button {
  background-color: #6c757d;
  margin-top: 1rem;
}

.retour-button:hover {
  background-color: #5a6268;
}

.loader-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #555;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007BFF;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.anonymized-text {
  white-space: pre-wrap;
  border: none; /* rely on the parent .text-section border */
  background: inherit;
  font-size: 1rem;
  font-family: inherit;
  overflow: auto;
  max-height: 400px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
</style>
