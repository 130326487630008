<template>
  <div class="dashboard-container">

    <!-- Header Section -->
    <header class="dashboard-header">
      <button style="display: flex;" @click="goBack">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
          style="margin-right: 5px; margin-top: 4px"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0
               1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1
               16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147
               2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0
               0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707
               7.5z"
          />
        </svg>
        Retour
      </button>
      <h1>Query Juriste - Exemples pratiques</h1>
    </header>

    <!-- Categories Section -->
    <section class="categories-section">
      <div v-for="category in categorizedQuestions" :key="category.name" class="category-group">

        <!-- Category Header with Toggle -->
        <div class="category-header" @click="toggleCategory(category.name)">
          <button
            class="toggle-category-button"
            :class="{ 'rotated': isCategoryVisible(category.name) }"
            aria-label="Toggle Category"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="black"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </button>
          <h2 style="margin-left: 5px">{{ category.name }}</h2>
        </div>
        <!-- Questions List within the Category -->
        <ul v-if="isCategoryVisible(category.name)" class="questions-list">
          <li v-for="question in category.questions" :key="question.id">
            <!-- The entire card is clickable. Clicking it creates a dossier and navigates to DossierPage -->
            <div class="question-item" @click="createAndOpenDossier(question.id)">
              <div class="question-header">
                <span class="question-text">{{ question.text }}</span>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </section>
  </div>
</template>

<script>
import { db, auth } from "@/firebase/firebaseConfig";
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

export default {
  name: 'examplePage',
  data() {
    return {
      selectedQuestion: null,
      dossiers: [],
      specificationsVisibility: {},
      categoriesVisibility: {},

      // Questions list as provided
      questions: [
        // Droit des affaires
        {
          id: 'rupture_contrat_distribution',
          text: 'Une entreprise souhaite rompre un contrat de distribution exclusive avant son terme en invoquant une faute grave du distributeur. Quels sont les critères pour établir cette faute grave et éviter une indemnisation ?',
          specifications: [
            {
              id: 'droit_affaires_moyenne',
              title: 'Droit des affaires - Question contextuelle moyenne :',
              description: 'Une entreprise souhaite rompre un contrat de distribution exclusive avant son terme en invoquant une faute grave du distributeur. Quels sont les critères pour établir cette faute grave et éviter une indemnisation ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'abus_position_dominante',
          text: 'Une société française exporte régulièrement ses produits vers l\'Union européenne. Elle a récemment été accusée d\'abus de position dominante par un concurrent, qui soutient qu\'elle impose des prix prédateurs à ses clients dans certains États membres. Quels éléments de preuve et critères juridiques le concurrent devra-t-il apporter pour que cette accusation soit fondée selon le droit de la concurrence européen ?',
          specifications: [
            {
              id: 'droit_affaires_longue',
              title: 'Droit des affaires - Question longue et détaillée :',
              description: 'Une société française exporte régulièrement ses produits vers l\'Union européenne. Elle a récemment été accusée d\'abus de position dominante par un concurrent, qui soutient qu\'elle impose des prix prédateurs à ses clients dans certains États membres. Quels éléments de preuve et critères juridiques le concurrent devra-t-il apporter pour que cette accusation soit fondée selon le droit de la concurrence européen ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'conflit_earn_out_LBO',
          text: 'Une société de capital-investissement acquiert une entreprise familiale via un LBO (leveraged buyout). Après la transaction, un conflit émerge avec les actionnaires fondateurs concernant les modalités de calcul de l\'earn-out prévu dans le contrat. Quels sont les leviers juridiques pour trancher ce litige et protéger les intérêts de l’acquéreur ?',
          specifications: [
            {
              id: 'droit_affaires_complexe',
              title: 'Droit des affaires - Cas complexe et très contextuel :',
              description: 'Une société de capital-investissement acquiert une entreprise familiale via un LBO (leveraged buyout). Après la transaction, un conflit émerge avec les actionnaires fondateurs concernant les modalités de calcul de l\'earn-out prévu dans le contrat. Quels sont les leviers juridiques pour trancher ce litige et protéger les intérêts de l’acquéreur ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'litige_confidentialite_startup',
          text: 'Une startup technologique a récemment obtenu un financement important auprès de plusieurs investisseurs en capital-risque. Cependant, l’un des investisseurs souhaite désormais se retirer, invoquant une violation de la clause de confidentialité dans le pacte d’actionnaires, après qu’une discussion stratégique ait été relayée dans la presse. Quels mécanismes contractuels et juridiques peuvent être utilisés pour résoudre ce litige, tout en préservant la stabilité du tour de financement et des relations entre les parties ?',
          specifications: [
            {
              id: 'droit_affaires_pratique',
              title: 'Droit des affaires - Cas pratique structuré et multidimensionnel :',
              description: 'Une startup technologique a récemment obtenu un financement important auprès de plusieurs investisseurs en capital-risque. Cependant, l’un des investisseurs souhaite désormais se retirer, invoquant une violation de la clause de confidentialité dans le pacte d’actionnaires, après qu’une discussion stratégique ait été relayée dans la presse. Quels mécanismes contractuels et juridiques peuvent être utilisés pour résoudre ce litige, tout en préservant la stabilité du tour de financement et des relations entre les parties ?'
            }
          ],
          category: 'Droit des affaires'
        },

        // Droit fiscal
        {
          id: 'credit_impot_recherche',
          text: 'Une entreprise souhaite bénéficier d\'un crédit d\'impôt pour dépenses de recherche (CIR). Quels types de dépenses sont éligibles et comment doivent-elles être justifiées auprès de l\'administration fiscale ?',
          specifications: [
            {
              id: 'droit_fiscal_moyenne',
              title: 'Droit fiscal - Question contextuelle moyenne :',
              description: 'Une entreprise souhaite bénéficier d\'un crédit d\'impôt pour dépenses de recherche (CIR). Quels types de dépenses sont éligibles et comment doivent-elles être justifiées auprès de l\'administration fiscale ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'plus_value_immobilier',
          text: 'Un contribuable personne physique a réalisé une plus-value importante suite à la vente d’un bien immobilier non affecté à son habitation principale. Quels sont les abattements applicables pour durée de détention, et comment est calculée l\'imposition sur cette plus-value dans le cadre de l\'impôt sur le revenu et des prélèvements sociaux ?',
          specifications: [
            {
              id: 'droit_fiscal_longue',
              title: 'Droit fiscal - Question longue et détaillée :',
              description: 'Un contribuable personne physique a réalisé une plus-value importante suite à la vente d’un bien immobilier non affecté à son habitation principale. Quels sont les abattements applicables pour durée de détention, et comment est calculée l\'imposition sur cette plus-value dans le cadre de l\'impôt sur le revenu et des prélèvements sociaux ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'depenses_somptuaires_pme',
          text: 'Une PME française ayant opté pour le régime réel simplifié a récemment fait l\'objet d\'une vérification de comptabilité. L\'administration fiscale conteste l\'inscription de certaines charges en les qualifiant de dépenses somptuaires non déductibles. Quels critères permettent de déterminer si une dépense est déductible, et quels recours l’entreprise peut-elle engager face à une rectification fiscale ?',
          specifications: [
            {
              id: 'droit_fiscal_complexe',
              title: 'Droit fiscal - Cas complexe et très contextuel :',
              description: 'Une PME française ayant opté pour le régime réel simplifié a récemment fait l\'objet d\'une vérification de comptabilité. L\'administration fiscale conteste l\'inscription de certaines charges en les qualifiant de dépenses somptuaires non déductibles. Quels critères permettent de déterminer si une dépense est déductible, et quels recours l’entreprise peut-elle engager face à une rectification fiscale ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'transmission_parts_sociales',
          text: 'Une société familiale exploitant un commerce souhaite transmettre ses parts sociales à la génération suivante tout en limitant l’impact fiscal de cette opération. Quels mécanismes, tels que le pacte Dutreil, peuvent être utilisés pour réduire les droits de mutation, et quelles sont les conditions juridiques et fiscales à respecter pour en bénéficier ?',
          specifications: [
            {
              id: 'droit_fiscal_pratique',
              title: 'Droit fiscal - Cas pratique structuré et multidimensionnel :',
              description: 'Une société familiale exploitant un commerce souhaite transmettre ses parts sociales à la génération suivante tout en limitant l’impact fiscal de cette opération. Quels mécanismes, tels que le pacte Dutreil, peuvent être utilisés pour réduire les droits de mutation, et quelles sont les conditions juridiques et fiscales à respecter pour en bénéficier ?'
            }
          ],
          category: 'Droit fiscal'
        },

        // Droit de la famille
        {
          id: 'prestation_compensatoire',
          text: 'Un couple marié sous le régime de la séparation de biens souhaite divorcer. L’un des conjoints réclame une prestation compensatoire. Quels éléments seront pris en compte par le juge pour décider de l’attribution et du montant de cette prestation ?',
          specifications: [
            {
              id: 'droit_famille_moyenne',
              title: 'Droit de la famille - Question contextuelle moyenne :',
              description: 'Un couple marié sous le régime de la séparation de biens souhaite divorcer. L’un des conjoints réclame une prestation compensatoire. Quels éléments seront pris en compte par le juge pour décider de l’attribution et du montant de cette prestation ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'demenagement_parent',
          text: 'Deux parents divorcés partagent l\'autorité parentale d\'un enfant mineur. L\'un des parents souhaite déménager dans une autre région pour des raisons professionnelles, ce qui impacterait les modalités de garde. Quels sont les recours juridiques de l\'autre parent pour s’opposer à ce déménagement et maintenir un droit de visite régulier ?',
          specifications: [
            {
              id: 'droit_famille_longue',
              title: 'Droit de la famille - Question longue et détaillée :',
              description: 'Deux parents divorcés partagent l\'autorité parentale d\'un enfant mineur. L\'un des parents souhaite déménager dans une autre région pour des raisons professionnelles, ce qui impacterait les modalités de garde. Quels sont les recours juridiques de l\'autre parent pour s’opposer à ce déménagement et maintenir un droit de visite régulier ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'indemnisation_maison_separation',
          text: 'Un couple non marié s’est séparé après 10 ans de vie commune. Ils ont acheté une maison ensemble, mais seul l’un d’entre eux figure sur l’acte de propriété. Après leur séparation, l’autre demande une indemnisation pour avoir contribué au remboursement du crédit et à l’entretien de la maison. Quels sont les moyens juridiques pour établir une compensation financière dans ce cas ?',
          specifications: [
            {
              id: 'droit_famille_complexe',
              title: 'Droit de la famille - Cas complexe et très contextuel :',
              description: 'Un couple non marié s’est séparé après 10 ans de vie commune. Ils ont acheté une maison ensemble, mais seul l’un d’entre eux figure sur l’acte de propriété. Après leur séparation, l’autre demande une indemnisation pour avoir contribué au remboursement du crédit et à l’entretien de la maison. Quels sont les moyens juridiques pour établir une compensation financière dans ce cas ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'dettes_communautaires_divorce',
          text: 'Une femme découvre que son époux, avec lequel elle est mariée sous le régime de la communauté, a contracté plusieurs dettes importantes sans son consentement dans le cadre de son activité professionnelle. Suite à une procédure de divorce, elle s’inquiète des conséquences de ces dettes sur son patrimoine personnel. Quels sont les mécanismes de protection prévus par le droit pour limiter l’impact de ces dettes sur sa part de la communauté ? Quelles options s’offrent à elle pour contester sa responsabilité dans le remboursement de ces dettes ?',
          specifications: [
            {
              id: 'droit_famille_pratique',
              title: 'Droit de la famille - Cas pratique structuré et multidimensionnel :',
              description: 'Une femme découvre que son époux, avec lequel elle est mariée sous le régime de la communauté, a contracté plusieurs dettes importantes sans son consentement dans le cadre de son activité professionnelle. Suite à une procédure de divorce, elle s’inquiète des conséquences de ces dettes sur son patrimoine personnel. Quels sont les mécanismes de protection prévus par le droit pour limiter l’impact de ces dettes sur sa part de la communauté ? Quelles options s’offrent à elle pour contester sa responsabilité dans le remboursement de ces dettes ?'
            }
          ],
          category: 'Droit de la famille'
        },

        // Droit du travail
        {
          id: 'heures_sup_non_remunerees',
          text: 'Un salarié réclame le paiement d\'heures supplémentaires non rémunérées, mais l\'employeur conteste leur réalisation. Quels éléments le salarié doit-il apporter pour prouver qu’il a effectivement effectué ces heures ?',
          specifications: [
            {
              id: 'droit_travail_moyenne',
              title: 'Droit du travail - Question contextuelle moyenne :',
              description: 'Un salarié réclame le paiement d\'heures supplémentaires non rémunérées, mais l\'employeur conteste leur réalisation. Quels éléments le salarié doit-il apporter pour prouver qu’il a effectivement effectué ces heures ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'clause_non_concurrence',
          text: 'Un employeur souhaite mettre en place une clause de non-concurrence pour certains salariés après leur départ. Quels sont les critères de validité de cette clause en droit du travail français, et quelles conséquences en cas de non-respect par l\'employeur ?',
          specifications: [
            {
              id: 'droit_travail_longue',
              title: 'Droit du travail - Question longue et détaillée :',
              description: 'Un employeur souhaite mettre en place une clause de non-concurrence pour certains salariés après leur départ. Quels sont les critères de validité de cette clause en droit du travail français, et quelles conséquences en cas de non-respect par l\'employeur ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'licenciement_apres_burn_out',
          text: 'Un salarié a été placé en arrêt maladie pendant plusieurs mois suite à un burn-out. Pendant cette période, l’employeur a réorganisé l’entreprise, supprimant le poste du salarié pour motif économique. Ce dernier conteste son licenciement, estimant que l’employeur a abusé de la situation de fragilité liée à sa maladie. Quels sont les risques juridiques pour l’employeur, et comment justifier la réalité et le sérieux du motif économique ?',
          specifications: [
            {
              id: 'droit_travail_complexe',
              title: 'Droit du travail - Cas complexe et très contextuel :',
              description: 'Un salarié a été placé en arrêt maladie pendant plusieurs mois suite à un burn-out. Pendant cette période, l’employeur a réorganisé l’entreprise, supprimant le poste du salarié pour motif économique. Ce dernier conteste son licenciement, estimant que l’employeur a abusé de la situation de fragilité liée à sa maladie. Quels sont les risques juridiques pour l’employeur, et comment justifier la réalité et le sérieux du motif économique ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'reorganisation_temps_travail',
          text: 'Une entreprise souhaite modifier l’organisation du temps de travail en passant d’un horaire fixe à une annualisation du temps de travail pour ses employés. Certains salariés refusent cette modification, arguant qu\'elle change substantiellement leur contrat de travail. Quels sont les moyens légaux pour l’employeur de mettre en place cette réorganisation, tout en respectant les droits des salariés et les obligations conventionnelles ? Quels recours ces derniers peuvent-ils engager en cas de désaccord ?',
          specifications: [
            {
              id: 'droit_travail_pratique',
              title: 'Droit du travail - Cas pratique structuré et multidimensionnel :',
              description: 'Une entreprise souhaite modifier l’organisation du temps de travail en passant d’un horaire fixe à une annualisation du temps de travail pour ses employés. Certains salariés refusent cette modification, arguant qu\'elle change substantiellement leur contrat de travail. Quels sont les moyens légaux pour l’employeur de mettre en place cette réorganisation, tout en respectant les droits des salariés et les obligations conventionnelles ? Quels recours ces derniers peuvent-ils engager en cas de désaccord ?'
            }
          ],
          category: 'Droit du travail'
        }
      ]
    };
  },
  computed: {
    // Compute categorized questions for easier rendering
    categorizedQuestions() {
      const categories = {};

      this.questions.forEach(question => {
        if (!categories[question.category]) {
          categories[question.category] = [];
        }
        categories[question.category].push(question);
      });

      // Convert the categories object into an array for v-for
      return Object.keys(categories).map(categoryName => ({
        name: categoryName,
        questions: categories[categoryName]
      }));
    }
  },
  created() {
    // Initialize specifications visibility for all questions
    this.questions.forEach(q => {
      this.$set(this.specificationsVisibility, q.id, false);
    });

    // Initialize categories visibility (all expanded by default)
    this.categorizedQuestions.forEach(cat => {
      this.$set(this.categoriesVisibility, cat.name, false);
    });
  },
  methods: {
    // Toggle specifications visibility for a specific question
    toggleSpecifications(questionId) {
      this.specificationsVisibility[questionId] = !this.specificationsVisibility[questionId];
    },
    isSpecificationsVisible(questionId) {
      return this.specificationsVisibility[questionId];
    },

    goBack() {
      this.$router.go(-1);
    },

    // Toggle visibility for a specific category
    toggleCategory(categoryName) {
      this.categoriesVisibility[categoryName] = !this.categoriesVisibility[categoryName];
    },
    isCategoryVisible(categoryName) {
      return this.categoriesVisibility[categoryName];
    },

    // Optional: Get question text (if needed elsewhere)
    getQuestionText(questionId) {
      const question = this.questions.find(q => q.id === questionId);
      return question ? question.text : '';
    },

    // Create a dossier and navigate to the dossier page
    async createAndOpenDossier(questionId) {
      try {
        const user = auth.currentUser;
        if (!user) {
          alert('Veuillez vous connecter pour créer un dossier.');
          return;
        }

        const userId = user.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');

        const newDossier = {
          name: 'Nouveau dossier',
          createdAt: serverTimestamp()
        };

        const docRef = await addDoc(dossiersRef, newDossier);
        this.dossiers.unshift({ id: docRef.id, ...newDossier });

        // Use this.$router since we are in Vue 2 Options API
        this.$router.push({
          name: 'DossierPage',
          params: {
            dossierId: docRef.id,
            questionId: questionId || undefined
          }
        });
      } catch (error) {
        console.error('Error creating dossier:', error);
        alert('Erreur lors de la création du dossier.');
      }
    }
  }
};
</script>

<style scoped>
.dashboard-container {
  font-weight: 300;
  background-color: #ffffff;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.dashboard-header h1 {
  margin: 0;
  font-size: 2em;
  text-align: center;
}

.categories-section {
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.category-group {
  margin-bottom: 30px;
}

.category-header {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: .5px solid #d3d3d3;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;
}

.category-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.toggle-category-button {
  background: transparent;
  border: none;
  color: #007BFF;
  cursor: pointer;
  font-size: 1em;
  text-decoration: underline;
}

.toggle-category-button:hover {
  color: #0056b3;
}

.questions-list {
  list-style-type: none;
  padding: 10px 0 0 0;
}

.toggle-category-button .bi-chevron-right {
  transition: transform 0.3s ease;
}

.toggle-category-button.rotated .bi-chevron-right {
  transform: rotate(90deg);
}

.question-item {
  display: block;
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: .5px solid #d3d3d3;
  border-radius: 8px;
  text-decoration: none;
  color: #000;
  transition: background 0.3s;
  cursor: pointer;
  font-style: italic;
  font-weight: 400;
}

.question-item:hover {
  background: #f2f2f2;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-text {
  flex: 1;
}

.specifications {
  margin-top: 15px;
}

.toggle-button {
  background: transparent;
  color: #007BFF;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  text-decoration: underline;
  padding: 0;
}

.toggle-button:hover {
  color: #0056b3;
}

.specifications-content {
  margin-top: 10px;
  padding-left: 15px;
}

.spec-item {
  margin-bottom: 15px;
}

.spec-item h3 {
  margin: 0 0 5px 0;
  font-size: 1.1em;
  color: #333;
}

.spec-item p {
  margin: 0 0 10px 0;
  line-height: 1.5;
}
</style>
