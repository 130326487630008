<template>
  <div class="dashboard">
    <header class="dashboard-header">
      <h1>Anonymisation Dashboard</h1>
    </header>

    <section class="actions">
      <button @click="createNewAnonymisationPage" class="primary-button">
        Créer une nouvelle anonymisation
      </button>
    </section>

    <section v-if="decisions.length > 0" class="decision-list">
      <h2>Décisions Anonymisées</h2>
      <ul>
        <li v-for="decision in decisions" :key="decision.id">
          <router-link :to="`/anonymisationPage/${decision.id}`" class="decision-link">
            {{ decision.id }}
          </router-link>
        </li>
      </ul>
    </section>

    <section v-else class="no-decisions">
      <p>Aucune décision anonymisée trouvée.</p>
    </section>
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "AnonymisationDashboard",
  data() {
    return {
      decisions: [],
      isLoading: false, // For loading indicator
    };
  },
  methods: {
    async fetchDecisions() {
      this.isLoading = true; // Start loading
      try {
        const userId = auth.currentUser.uid;
        const decisionsCollection = collection(db, "users", userId, "anonyme_decisions");
        const q = query(decisionsCollection, orderBy("createdAt", "desc")); // Your specified query logic
        const snapshot = await getDocs(q);

        this.decisions = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error("Erreur lors de la récupération des décisions:", error);
        // Optionally, handle the error in the UI
      } finally {
        this.isLoading = false; // End loading
      }
    },
    createNewAnonymisationPage() {
      const newId = uuidv4(); // Generate a new anonymisation ID
      this.$router.push(`/anonymisationPage/${newId}`);
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
      return date.toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  },
  mounted() {
    this.fetchDecisions();
  }
};
</script>

<style scoped>
/* General Styles */
.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9fafb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Header Styles */
.dashboard-header {
  text-align: center;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

.dashboard-header h1 {
  font-size: 2.5rem;
  color: #1f2937;
}

/* Actions Section */
.actions {
  display: flex;
  justify-content: center;
}

.primary-button {
  background-color: #3b82f6;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: #2563eb;
}

/* Decision List Styles */
.decision-list {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.decision-list h2 {
  font-size: 1.75rem;
  color: #374151;
  margin-bottom: 1rem;
  text-align: center;
}

.decision-list ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.decision-list li {
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: transform 0.2s, box-shadow 0.2s;
}

.decision-list li:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.decision-link {
  text-decoration: none;
  color: #2563eb;
  font-weight: 500;
  display: block;
  text-align: center;
}

.decision-link:hover {
  color: #1d4ed8;
}

/* No Decisions Message */
.no-decisions {
  text-align: center;
  color: #6b7280;
  font-size: 1.25rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard {
    padding: 1rem;
  }

  .dashboard-header h1 {
    font-size: 2rem;
  }

  .primary-button {
    width: 100%;
    padding: 0.75rem;
  }

  .decision-list ul {
    grid-template-columns: 1fr;
  }
}
</style>
